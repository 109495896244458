<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-8">
            <card-add-general-information-guide
              ref="cardAddGeneralInformationGuideRef"
            />
          </div>
          <div class="column is-4">
            <card-upload-file
              nameInputFile="coverImage"
              titleCard="PORTADA (OPCIONAL)"
              :previewImage="previewImageCover"
              :setFile="setFileCover"
              :modeEdit="true"
              validate="ext:jpeg,jpg,png"
              accept=".jpeg,.jpg,.png,"
            />
          </div>
          <div class="column is-8">
            <card-add-related-products
              ref="cardAddRelatedProductRef"
              :guidesProductOptions="guidesProductOptions"
            />
          </div>
          <div class="column is-4">
            <card-upload-file
              nameInputFile="guideFile"
              titleCard="Guía de selección"
              :previewImage="previewImageGuide"
              :setFile="setFileGuide"
              :modeEdit="true"
              validate="required|ext:jpeg,jpg,png"
              accept=".jpeg,.jpg,.png,"
              style="margin-bottom: 20px;"
            />
            <card-upload-file
              typeFile="pdf"
              class="card_upload"
              :whitBorder="true"
              nameInputFile="pdfFileDataSheet"
              titleCard="Ficha técnica (PDF)"
              :previewImage="previewImageDataSheet"
              :setFile="setFileDataSheet"
              :modeEdit="true"
              validate="ext:pdf"
              accept=".pdf"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SelectionGuideMainDetail',
  inject: ['$validator'],
  components: {
    CardAddGeneralInformationGuide: () => import('@/components/SelectionGuide/Add/CardAddGeneralInformationGuide.vue'),
    CardUploadFile: () => import('@/components/UI/CardUploadFile.vue'),
    CardAddRelatedProducts: () => import('@/components/SelectionGuide/Add/CardAddRelatedProducts.vue')
  },
  data () {
    return {
      coverFileImage: null,
      previewImageCover: null,
      guideFileImage: null,
      previewImageGuide: null,
      pdfFileDataSheet: null,
      previewImageDataSheet: null,
      relatedProducts: [],
      guidesProductOptions: []
    }
  },
  props: {
    loadingProd: {
      type: Boolean,
      default: false
    },
    guidesProductList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    setFileCover (event) {
      if (event) {
        const [file] = event.target.files
        this.coverFileImage = { key: event.target.name, file }
        // Preview
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = e => {
          this.previewImageCover = e.target.result
        }
      }
    },
    setFileGuide (event) {
      const [file] = event.target.files
      this.guideFileImage = { key: event.target.name, file }
      // Preview
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.previewImageGuide = e.target.result
      }
    },
    async setFileDataSheet (event) {
      if (event) {
        const [file] = event.target.files
        // Preview
        if (file) {
          this.previewImageDataSheet = true
          this.pdfFileDataSheet = { key: event.target.name, file }
        }
      }
    },
    async getDataContruct () {
      const relatedProducts = await this.$refs.cardAddRelatedProductRef.getDataProducts()
      const generalData = await this.$refs.cardAddGeneralInformationGuideRef.getDataGeneralInformation()
      const childData = {
        relatedProducts: relatedProducts,
        generalData: generalData,
        coverImage: this.coverFileImage,
        guideFile: this.guideFileImage,
        pdfFileDataSheet: this.pdfFileDataSheet
      }
      return childData
    }
  },
  beforeMount () {
    this.guidesProductOptions = this.guidesProductList
  }
}
</script>

<style lang="scss" scoped>
  .column:nth-child(1) .card,
  .column:nth-child(2) .card {
    height: 100%;
  }
</style>
